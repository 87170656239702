const baseUrl = process.env.REACT_APP_BASE_URL;  
const userDetails = JSON.parse(localStorage.getItem('userDetails'));

export const fetchItemUserRole = async (userID) => {
   try {
    const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
     const response = await fetch(`${baseUrl}saveusers/${userID}`,{headers});
     if (!response.ok) {
       throw new Error('Network response was not ok');
     }
     const data = await response.json();
     return data;
   } catch (error) {
     throw new Error(`Error fetching items: ${error.message}`);
   }
 };

 export const getItemUserRole = async (username) => {
    try {
      const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
      const response = await fetch(`${baseUrl}getuserrole/${username}`,{headers});
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error fetching items: ${error.message}`);
    }
  };