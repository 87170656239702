import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { IMAGES } from '../../../../constant/theme';

const options1 = [
    { value: '1', label: 'Computer Science' },
    { value: '2', label: 'Mathematics' },

]

const options2 = [
    { value: '1', label: 'Cyber Security' },
    { value: '2', label: 'Statistics' },

]

const options3 = [
    { value: '1', label: '2001' },
    { value: '2', label: '2002' },

];

const options4 = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },

]

const Index = () => {
    const [startDate2, setStartDate2] = useState(new Date());
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                   
                    <div className="col-xl-12 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Student Biodata</h6>
                            </div>
                            <form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                                   
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Matric No. </label>
                                            <input type="text" className="form-control" placeholder="Student Matriculation No" required/>
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Surname </label>
                                            <input type="text" className="form-control" placeholder="Surname" required/>
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">First Name </label>
                                            <input type="text" className="form-control" placeholder="First Name" required />
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Other name </label>
                                            <input type="text" className="form-control" placeholder="Othername" />
                                        </div>
                                       
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Email </label>
                                            <input type="email" className="form-control" placeholder="Email Address" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Phone </label>
                                            <input type="text" className="form-control" placeholder="Phone Number" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Birth</label>                                              
                                            <DatePicker 
                                                className="form-control"
                                                selected={startDate2} 
                                                onChange={(date) => setStartDate2(date)} 
                                            />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Gender</label>
                                            <Select options={options4}  className="custom-react-select" 
                                            defaultValue={options4[0]}
                                            isSearchable={true}
                                        /></div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Department</label>
                                            <Select options={options1}  className="custom-react-select" 
                                            defaultValue={options1[0]}
                                            isSearchable={true}
                                        />
                                         </div>
                           
                                 
                                           <div className="col-sm-4 m-b30">
                                                <label className="form-label">Year of Graduation</label>
                                                <Select options={options3}  className="custom-react-select" 
                                                defaultValue={options3[0]}
                                                isSearchable={true}
                                            />
                                            </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Award Date</label>                                              
                                            <DatePicker 
                                                className="form-control"
                                                selected={startDate2} 
                                                onChange={(date) => setStartDate2(date)} 
                                            />
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Thesis title </label>
                                            <input type="text" className="form-control" placeholder="Thesis title" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Overall GPA/average </label>
                                            <input type="text" className="form-control" placeholder="Overall GPA/average" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Student</button>
                                  
                                </div>
                               
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;