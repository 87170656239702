import { SVGICON } from "../../constant/theme";
const userDetails = JSON.parse(localStorage.getItem('userDetails'));

let shortname =null;
 if(userDetails) shortname = userDetails.shortname;
export const MenuUserList = [ 
      //Manage School Info
        //Content
        // {
        //     title: `${shortname}`,
        //     classsChange: 'menu-title'
        // },
        //Dashboard
        {
            title: 'MENU',		
            iconStyle: SVGICON.Home,             
            title: 'Home',
            to: '/dashboard',                     
        },
        //School Setup
        {
            title: 'Setup',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.TaskIcon,
            content:
             [
                
                {
                    title: 'Create Admin Users',
                    to: '/addUsers',                
                },
                {
                    title: 'Create School Info',
                    to: '/createSchoolInfo',                
                },
                {
                    title: 'Create Department',
                    to: '/addSchoolDept',                
                },
                {
                    title: 'Create Programme',
                    to: '/addSchoolProgramme',                
                },
                {
                    title: 'Create Semester',
                    to: '/addSchoolSemester',                
                },
                { 
                    title: 'Create Faculty',
                    to: '/addFaculty'
                },
                { 
                    title: 'Create Session',
                    to: '/addSession'
                },
                { 
                    title: 'Create Level',
                    to: '/addlevel'
                },
                { 
                    title: 'Create Degree Class',
                    to: '/degreeClass'
                },
                { 
                    title: 'Create Graduation Year',
                    to: '/graduationYear'
                },
                {
                    title: 'Create Carrier',
                    to: '/addCorriers',                 
                },
               
                { 
                    title: 'Create Shipping',
                    to: '/addShipping'
                },
               
               
               
            ],
        }, 
      
        {
            title: 'Courses',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.FolderHeart,
            content: [             
            
                {
                    title: 'Create Courses',
                    to: '/addSchoolCourses',                
                },
                {
                    title: 'Create Curriculum',
                    to: '/addCurriculum',                
                },
               
            ],
        },   

        {
            title: 'Results',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.TaskIcon,
            content: [             
                { 
                    title: 'Add Grade System',
                    to: '/addGrade'
                },
                {
                    title: 'Create Student',
                    to: '/addStudents',                  
                },
                { 
                    title: 'Add Student Scores',
                    to: '/addScores'
                },
                {
                    title: 'Generate Transcript',
                    to: '/generateTranscripts',
                },
                {     
                    to: '/displayResult'
                },
               
            ],
        },   
        //Upload Files
        {
            title: 'Upload Files',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.TaskIcon,
            content: [             
             
               {
                title: 'Upload Department',
                to: '/impDepartments',
               },
               {
                title: 'Upload Programme',
                to: '/impProgrammes',
               },
               {
                title: 'Upload Semester',
                to: '/impSemesters',
               },
               {
                title: 'Upload Courses',
                to: '/impCourses',
               },
               {
                title: 'Upload Curriculum',
                to: '/impCurriculum',
               },
               {
                title: 'Upload Student Data',
                to: '/impStudents',
               },
               {
                title: 'Upload Student Result',
                to: '/impResults',
               },
               {
               title: 'Upload Faculty',
               to: '/impFaculty',
              },
               {
                title: 'Upload Semester Result',
                to: '/impSemesterResult',
               },
              
              
               
            ],
        },   
       //Admin Reports
        {
            title:'Reports',
            classsChange: 'mm-collapse',
            iconStyle:SVGICON.Table,
            content : [
                {
                    title:'School List',
                    to: 'reportsSchoolListing',
                },
                {
                    title:'View Department',
                    to: 'viewDepartments',
                },
                {
                    title:'View Programme',
                    to: 'viewProgrammes',
                },
                {
                    title:'View Semesters',
                    to: 'viewSemesters',
                },
                {
                    title:'View Courses',
                    to: 'viewCourses',
                },
                {
                    title:'View Users',
                    to: 'viewUsers',
                },
                {
                    title:'View Student',
                    to: 'viewStudents',
                },
                {
                    title:'View Shipping Cost',
                    to: 'viewShipping',
                },
            
                {
                    title:'View Applications',
                    to: 'viewApplications',
                },

                {
                    title:'View Grades',
                    to: 'viewGrades',
                },
            
            ]
        },
        {
            title: 'Settings',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.Folder,
            content: [  
                { 
                    title: 'Change Logo',
                    to: '/changeLogo'
                },

                { 
                    title: 'Add Role',
                    to: '/addRoles'
                },
                { 
                    title: 'Assign Role',
                    to: '/assignRole'
                },
                {
                    title: 'API Documentations',
                    to: 'http://3.87.90.30:8000/docs/',                
                },
                {
                    title: 'Api Key',
                    to: '/accesskey',                
                },
                {
                    title: 'Logout',
                    to: '/logout',                
                },
            ],
        },

]
export const MenuList = [
        // //Content
        // {
        //     title: 'DOOCUMEE',
        //     classsChange: 'menu-title'
        // },
        //Dashboard
        {
            title: 'MENU',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.Home,
            content: [
            
                {
                    title: 'Home',
                    to: '/dashboard',                
                },
            ],
        },
        //School Setup
        {
            title: 'Setup',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.TaskIcon,
            content:
             [
                
                {
                    title: 'Create Admin Users',
                    to: '/addUsers',                
                },
                {
                    title: 'Create School Info',
                    to: '/createSchoolInfo',                
                },
                {
                    title: 'Create Department',
                    to: '/addSchoolDept',                
                },
                {
                    title: 'Create Programme',
                    to: '/addSchoolProgramme',                
                },
                {
                    title: 'Create Semester',
                    to: '/addSchoolSemester',                
                },
                {
                    title: 'Create Courses',
                    to: '/addSchoolCourses',                
                },
                {
                    title: 'Create Carrier',
                    to: '/addCorriers',                 
                },

                {
                    title: 'Create Student',
                    to: '/addStudents',                  
                },
                { 
                    title: 'Create Shipping',
                    to: '/addShipping'
                },
                { 
                    title: 'Assign Role',
                    to: '/assignRole'
                },
                { 
                    title: 'Add Student Scores',
                    to: '/addScores'
                },
                { 
                    title: 'Add Grade System',
                    to: '/addGrade'
                },
                { 
                    title: 'Add Faculty',
                    to: '/addFaculty'
                },
                { 
                    title: 'Add Session',
                    to: '/addSession'
                },
                { 
                    title: 'Add Level',
                    to: '/addlevel'
                },
                { 
                    title: 'Add Role',
                    to: '/addRoles'
                },
                {
                    title: 'Generate Transcript',
                    to: '/generateTranscripts',
                },
                {     
                    to: '/displayResult'
                },
                { 
                    title: 'Transcript Header',
                    to: '/transcriptHeader'
                },
                {
                    title: 'Transcript Header Setup',
                    to: '/tHeaderSetup'
                },
                {
                    title: 'Table Column Header Setup',
                    to: '/tableColumnHeader'
                }
            ],
        }, 
        //Manage School Info
        {
            title: 'Manage',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.Forms,
            content: [             
                {
                    title: 'Edit School Info',
                    to: '/editSchoolInfo',                
                },
                {
                    title: 'Remove School Info',
                    to: '/removeSchoolInfo',                
                },
               {
                title: 'Edit Department',
                to: '/editDepartments',
               },
               {
                title: 'Edit Programme',
                to: '/editProgrammes',
               },
               {
                title: 'Edit Courses',
                to: '/editCourses',
               },
               {
                title: 'Edit Semester',
                to: '/editSemesters',
               }
               ,
               {
                title: 'Edit Users',
                to: '/editUsers',
               }
               ,
               {
                title: 'Edit Corriers',
                to: '/editCorriers',
               }
            ],
        },
        //Upload Files
        {
            title: 'Upload Files',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.TaskIcon,
            content: [             
             
               {
                title: 'Upload Department',
                to: '/impDepartments',
               },
               {
                title: 'Upload Programme',
                to: '/impProgrammes',
               },
               {
                title: 'Upload Semester',
                to: '/impSemesters',
               },
               {
                title: 'Upload Courses',
                to: '/impCourses',
               },
               {
                title: 'Upload Student Data',
                to: '/impStudents',
               },
               {
                title: 'Upload Student Result',
                to: '/impResults',
               },
               {
               title: 'Upload Faculty',
               to: '/impFaculty',
              },
               {
                title: 'Upload Title Header',
                to: '/impTitleHeader',
               },
               {
                title: 'Upload Footer Header',
                to: '/impFooterHeader',
               },
              
               
            ],
        },   
       //Admin Reports
        {
            title:'Reports',
            classsChange: 'mm-collapse',
            iconStyle:SVGICON.Table,
            content : [
                {
                    title:'School List',
                    to: 'reportsSchoolListing',
                },
                {
                    title:'View Department',
                    to: 'viewDepartments',
                },
                {
                    title:'View Programme',
                    to: 'viewProgrammes',
                },
                {
                    title:'View Semesters',
                    to: 'viewSemesters',
                },
                {
                    title:'View Courses',
                    to: 'viewCourses',
                },
                {
                    title:'View Users',
                    to: 'viewUsers',
                },
                {
                    title:'View Student',
                    to: 'viewStudents',
                },
                {
                    title:'View Shipping Cost',
                    to: 'viewShipping',
                },
            
                {
                    title:'View Applications',
                    to: 'viewApplications',
                },

                {
                    title:'View Grades',
                    to: 'viewGrades',
                },
            
            ]
        },
        {
            title: 'Settings',	
            classsChange: 'mm-collapse',		
            iconStyle: SVGICON.Folder,
            content: [
            
                {
                    title: 'Activate School',
                    to: '/viewSchools',                
                },
                {
                    title: 'Api Key',
                    to: '/accesskey',                
                },
            ],
        },


    
    
]