import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { IMAGES } from '../../../../constant/theme';



const Index = () => {
   const [file, setFile] = useState();
   function handleChange(e) {
       console.log(e.target.files);
       setFile(URL.createObjectURL(e.target.files[0]));
   }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Courier </h6>
                            </div>
                            <form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                            
                                  
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload Courier</label><a href='/downloadables/courier.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="formFile" />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;