import React, { Fragment, useState, useReducer } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'



const examTopper = [
  {id:'111', color:'success', status:'Successful'},
];

const sidebarLink = [
  {to:'/addGrade', title:'Add Grade'},
  {to:'/addScores', title:'Add Scores'},

 
];
const options3 = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' }
]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const [activeLink ,setActiveLink] = useState(0);
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [fieldValue, setFieldValue]=useState();
    const [selectGradeValue, setselectGradeValue] = useState();

    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };

    const selectGradeOnChange = (selectedOption) => 
    {
      setFieldValue('grade', selectedOption.value);
      setselectGradeValue(selectedOption);
    };
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savegrade";
        const userData ={
                    grade: selectGradeValue.value,
                    minscore : e.target.minscore.value,
                    maxscore:e.target.maxscore.value,
                    point :e.target.point.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
    }


    return(
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-2 col-lg-4">
                       <div className="demo-right ">
                          <div className="demo-right-inner dlab-scroll " id="right-sidebar">
                            <h4 className="title">MENU</h4>
                            <ul className="navbar-nav" id="menu-bar">
                                {sidebarLink.map((item, ind)=>(
                                  <li key={ind}                        
                                  >
                                    <Link to={item.to}  smooth={true}
                                      className={`scroll ${ind === activeLink ? 'active' :  ''} `}
                                      activeClass="active"
                                      spy={true}
                                      onClick={()=>setActiveLink(ind)}
                                    > 
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                            
                             </ul>	
                          </div>
                        </div>
                      </div>


                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Grade System </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                        
                                      <div className="col-sm-2 m-b30">
                                            <label className="form-label">From </label>
                                            <input type="text" className="form-control" id="minscore" name="minscore" placeholder="70" required />
                                        </div>

                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">To </label>
                                            <input type="text" className="form-control" id="maxscore" name="maxscore" placeholder="100" required />
                                        </div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Point </label>
                                            <input type="text" className="form-control" placeholder="Point" id="point" name="point" required />
                                        </div>
                                    <div className="col-sm-2 m-b30">
                                        <label className="form-label">Grade</label>
                                        <Select options={options3}  className="custom-react-select" 
                                            onChange={selectGradeOnChange}
                                            value={selectGradeValue}
                                            defaultValue={options3[0]}
                                            isSearchable={true} required
                                        />
                                    </div> 
                                    
                                       
                                      
                                        
                                    </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Grade</button>
                                  
                                </div>
                                </div>                              
                            </form>

                        </div>
                    </div>


                    
                </div>
    {/* Record Listing */}
    <div className="row">

        <div className="container-fluid">
         <Row>
            <div className="col-xl-1 col-lg-4">
                    
            </div>
         <div className="col-xl-9 col-lg-8">
            <Card>
              <Card.Header>
                <Card.Title>Edit/Remove Grade </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width50 ">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            required=""
                            onClick={()=>handleCheckedAll(unchecked)}  
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>NO.</strong>
                      </th>
                      <th>
                        <strong>From</strong>
                      </th>
                      <th>
                        <strong>To</strong>
                      </th>
                      <th>
                        <strong>Grade</strong>
                      </th>
                      <th>
                        <strong>Point</strong>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checked.map((item, index)=>(
                      <tr key={index}>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`exam-${item.id}`}
                              checked={item.inputchecked}
                              onChange={()=>handleChecked(item.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`exam-${item.id}`}
                            ></label>
                          </div>
                        </td>
                        
						<td>1</td>
                        <td>70</td>
                        <td>100</td>
                        <td>A</td>
                        <td>5</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
							  onClick={() => dispatch({type:'basicModal'})}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'})}
                              to={"#"}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </Row>
       </div>
           {/* <!-- Modal --> */}
			<Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
							<Modal.Header>
								<Modal.Title>Edit Grades </Modal.Title>
								<Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
							</Modal.Header>
							<Modal.Body>
								
                            <form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                        
                                      <div className="col-sm-3 m-b30">
                                            <label className="form-label">From </label>
                                            <input type="text" className="form-control" placeholder="70" />
                                        </div>

                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">To </label>
                                            <input type="text" className="form-control" placeholder="100" />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Point </label>
                                            <input type="text" className="form-control" placeholder="5" />
                                        </div>
                                    <div className="col-sm-3 m-b30">
                                        <label className="form-label">Grade</label>
                                        <Select options={options3}  className="custom-react-select" 
                                            defaultValue={options3[0]}
                                            isSearchable={true}
                                        />
                                    </div> 
                                       
                                       
                                      
                                        
                                    </div>
                                </div>
                              
                            </form>
							

							</Modal.Body>
							<Modal.Footer>
								<Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
								<Button variant="primary">Update Grade  </Button>
							</Modal.Footer>
						</Modal>
         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
			<Modal.Header>
				<Modal.Title>Delete Grade</Modal.Title>
				<Button
				variant=""
				className="btn-close"
				onClick={() => dispatch({type:'smallModal'})}
				>
				
				</Button>
			</Modal.Header>
			<Modal.Body>
				<span style={{ color:'red' }}>Are you sure you want to delete this grade  information?</span>
			</Modal.Body>
			<Modal.Footer>
				<Button
				variant="danger light"
				onClick={() => dispatch({type:'smallModal'})}
				>
				Close
				</Button>
				<Button variant="danger">Delete</Button>
			</Modal.Footer>
         </Modal>
    </div>
 {/* End of Record listing */}

    </div>
    
        </>
    )
}
export default Index;