import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";


const Index = () => {

  return (
    <Fragment>
      {/* <PageTitle activeMenu="Bootstrap" motherMenu="Table" /> */}
      <div className="container-fluid">
      <Row>
      <Col lg={1}></Col>
      <Col lg={10}>
            <Card>
                <Card.Body style={{ fontSize:'30px', alignContent:'center' }} >
                  <label style={{ fontSize:'30px', alignContent:'center' }}>SCHOOL OF APPLIED SCIENCE</label>
                  <br/>
                  <label>DEPARTMENT OF STATISTICS</label>      
                     
              </Card.Body>
            
            </Card>
        
        </Col>
        <Col lg={1}></Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Index;
