import React,{ useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../DATA/MOCK_DATA_SEMESTER.json';
import { COLUMNS } from './ColumnSemester';
import { GlobalFilter } from '../GlobalFilter'; 
import '../filtering.css';
import { Col, Row } from 'react-bootstrap';


export const Index = () => {
	const columns = useMemo( () => COLUMNS, [] )
	const data = useMemo( () => MOCK_DATA, [] )
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
    //console.log(data);
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
	
	
	const {globalFilter, pageIndex} = state	
	return(
		<>
			
			<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Semester Filtering</h4>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
									<table {...getTableProps()} className="table dataTable display">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
													{headerGroup.headers.map(column => (
														<th {...column.getHeaderProps()}>
															{column.render('Header')}
															{column.canFilter ? column.render('Filter') : null}
														</th>
													))}
												</tr>
										))}
										</thead> 
										<tbody {...getTableBodyProps()} className="" >
										
											{page.map((row) => {
												prepareRow(row)
												return(
													<tr {...row.getRowProps()}>
														{row.cells.map((cell) => {
															return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
														})}
													</tr>
												)
											})}
										</tbody>
									</table>
									<div className="d-flex justify-content-between">
										<span>
											Page{' '}
											<strong>
												{pageIndex + 1} of {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index">
											Go to page : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
									<div className="text-center mb-3">	
										<div className="filter-pagination  mt-3">
											<button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
												{/* {'<'} */}
												<i className='fa-solid fa-angle-left' />
											</button>
											
											<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
												Previous
											</button>
											<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
												Next
											</button>
											<button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
												{/* {'>'} */}
												<i className='fa-solid fa-angle-right' />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>

		</>
	)
	
}
export default Index;