import React,{useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import CourierIndex from '../../Reports/Views/Courier/'
const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [fieldValue, setFieldValue]=useState();
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savecourier";
        const userData ={
                   
                    name:e.target.courier.value,
                    email : e.target.email.value,
                    phone:e.target.phone.value,
                    address:e.target.address.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
        //window.location.reload();
    }


    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Courier  </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                        
                                     
                                        <div className="col-sm-10 m-b30">
                                            <label className="form-label">Courier name </label>
                                            <input type="text" className="form-control" id="courier" name="courier" placeholder="" required />
                                        </div>
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Address </label>
                                            <input type="text" className="form-control" id="address" name="address" placeholder="Address" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Email </label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" required  />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Phone </label>
                                            <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success">Add Carrier</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <CourierIndex />
                </div>
            </div>
        </>
    )
}
export default Index;