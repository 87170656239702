import React, { Fragment } from "react";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const radiocheckBox = [
  {title:'Checkbox 1', check: 'primary'}, {title:'Checkbox 2', check: 'secondary'}, 
  {title:'Checkbox 3',check: 'info'}, {title:'Checkbox 4', check: 'success'}, 
  {title:'Checkbox 5', check: 'warning'}, {title:'Checkbox 6', check: 'danger'}, 
];

const Index = () => {
  return (
    <Fragment>      
      <div className="container-fluid">
        <div className="row">
   

          {/* <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transcript Title Header</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                      
                      <div className="form-check mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check1"
                          value=""
                          defaultChecked
                        />
                        <label className="form-check-label" htmlFor="check1">
                          FullName
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check2"
                          value=""
                        />
                        <label className="form-check-label" htmlFor="check2">
                          Academic Session
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check2"
                          value=""
                        />
                        <label className="form-check-label" htmlFor="check2">
                         Department
                        </label>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}


          <div className="col-xl-10 col-lg-6">
            <div className="card">
              <div className="card-header">
              <h4 className="card-title">Table Column Header</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">

                    <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                           
                          />
                        SN
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                           
                          />
                         Coursecode
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                          />
                            Course Title
                        </label>
                      </div>
                      <div className="form-check form-check-inline disabled">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                            
                          />
                          Course Unit
                        </label>
                      </div>
                      <div className="form-check form-check-inline disabled">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                            
                          />
                          Score
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                           
                          />
                         Point
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value=""
                           
                          />
                         Grade
                        </label>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          
         
          </div>
      </div>
    </Fragment>
  );
};

export default Index;
