import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemCourses } from '../../Lists/Courses';
import  { fetchItemsSession  } from '../../Lists/Session';
import  { fetchItemDepartment  } from '../../Lists/Department';
const options3 = [
    { value: 'Core', label: 'Core' },
    { value: 'Elective', label: 'Elective' },
    { value: 'Required', label: 'Required' },
]

const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [datalevel, setDataLevel]=useState([]);
    const [datadepartment, setDataDepartment]=useState([]);
    const [datasession, setDataSession]=useState([]);
    const [datacourse, setDataCourses]=useState([]);
    const [datasemester, setDataSemester]=useState([]);
    const [selectCourseStatusValue, setselectCourseStatusValue] = useState();
    const [selectSemesterValue, setselectSemesterValue] = useState();
    const [selectLevelValue, setselectLevelValue] = useState();
    const [selectCourseValue, setselectCourseValue] = useState();
    const [selectSessionValue, setselectSessionValue] = useState();
    const [selectDepartmentValue, setselectDepartmentValue] = useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [error, setError] = useState(null);
    const [fieldValue, setFieldValue]=useState();

    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savecurriculum";
        const userData ={
                    coursecode:selectCourseValue.value,
                    courseunit:e.target.courseunit.value,
                    level:selectLevelValue.value,
                    semester:selectSemesterValue.value,
                    session:selectSessionValue.value,
                    departmentcode: selectDepartmentValue.value,
                    coursestatus:selectCourseStatusValue.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        console.log(userData);
        dispatches(saveAction(userData,path,navigate));
    }

    const selectCourseStatusOnChange = (selectedOption) => 
    {
      setFieldValue('coursestatus', selectedOption.value);
      setselectCourseStatusValue(selectedOption);
    };
    
    const selectSemesterOnChange = (selectedOption) => 
    {
      setFieldValue('semester', selectedOption.value);
      setselectSemesterValue(selectedOption);
    };
    
    const selectLevelOnChange = (selectedOption) => 
    {
      setFieldValue('level', selectedOption.value);
      setselectLevelValue(selectedOption);
    };
    
    const selectCourseOnChange = (selectedOption) => 
    {
      setFieldValue('course', selectedOption.value);
      setselectCourseValue(selectedOption);
    };
    
    const selectSessioneOnChange = (selectedOption) => 
    {
      setFieldValue('session', selectedOption.value);
      setselectSessionValue(selectedOption);
      let session = selectedOption.value;
            loadsemester(session)
            loadLevel(session)
    };
    const selectDepartmentOnChange = (selectedOption) => 
    {
      setFieldValue('department', selectedOption.value);
      setselectDepartmentValue(selectedOption);
    };
      const loadLevel =(session)=>{
        axios.get(`${baseUrl}savelevel/${session}/${userDetails.userID}`).then(response => 
          {
            
             const customHeadings = response.data.data.map(item=>({
                         "value": item.name,
                         "label": item.name        
                      }))
                      setDataLevel(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
      const loadsemester =(session)=>{
        axios.get(`${baseUrl}savesemester/${session}/${userDetails.userID}`).then(response => 
            {
             
               const customHeadings = response.data.data.map(item=>({
                           "value": item.id,
                           "label": item.name        
                        }))
                        setDataSemester(customHeadings);
                }) .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
                });
    
    
      }
   

      useEffect(()=>{
        const fetchCourseData = async (userID) => {
            try 
            {
               const response = await fetchItemCourses(userID);
               const customHeadings = response.data.map(item=>({
                "value": item.coursecode,
                "label":item.coursecode + ' '+ item.coursetitle        
             }))
             setDataCourses(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchCourseData(userID)
     },[userID])
        
     useEffect(()=>{
        const fetchSessionData = async (userID) => {
            try 
            {
               const response = await fetchItemsSession(userID);
               const customHeadings = response.data.map(item=>({
                "value": item.id,
                "label":item.name      
             }))
             setDataSession(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchSessionData(userID)
     },[userID])
        
     useEffect(()=>{
        const fetchDepartmentData = async (userID) => {
            try 
            {
               const response = await fetchItemDepartment(userID);
               const customHeadings = response.data.map(item=>({
                "value": item.departmentcode,
                "label":item.name      
             }))
             setDataDepartment(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchDepartmentData(userID)
     },[userID])




    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Curriculum </h6>
                            </div>
                            <form  onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                        
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Course </label>
                                                <Select options={datacourse} id="course" name="course" value={selectCourseValue} onChange={selectCourseOnChange}  className="custom-react-select" 
                                                defaultValue={"Courses"}
                                                isSearchable={true} required
                                            />                                        </div>

                                  
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Unit </label>
                                            <input type="text" className="form-control" id="courseunit" name="courseunit" placeholder="4" required />
                                        </div>
                                       
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Course Status </label>
                                            <Select options={options3} id="coursestatus" name="coursestatus" value={selectCourseStatusValue} onChange={selectCourseStatusOnChange}  className="custom-react-select" 
                                            defaultValue={options3[0]}
                                            isSearchable={true} required
                                        />
                                        </div>

                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Session </label>
                                            <Select options={datasession} id="semester" name="semester" value={selectSessionValue} onChange={selectSessioneOnChange}  className="custom-react-select" 
                                            placeholder ="Session"
                                            isSearchable={true} required
                                        />
                                         </div>

                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Semester </label>
                                            <Select options={datasemester} id="semester" name="semester" value={selectSemesterValue} onChange={selectSemesterOnChange}  className="custom-react-select" 
                                            placeholder ="Semester"
                                            isSearchable={true} required
                                        />
                                         </div>

                                           <div className="col-sm-2 m-b30">
                                            <label className="form-label">Level </label>
                                            <Select options={datalevel} id="level" name="level" value={selectLevelValue} onChange={selectLevelOnChange}  className="custom-react-select" 
                                             placeholder="Level"
                                            isSearchable={true} required
                                        />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Department </label>
                                            <Select options={datadepartment} id="departmentcode" name="departmentcode" value={selectDepartmentValue} onChange={selectDepartmentOnChange}  className="custom-react-select" 
                                             placeholder="Department"
                                            isSearchable={true} required
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Course</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;