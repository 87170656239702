import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import  { fetchItemsSession } from '../../../Lists/Session';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const GraduationIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [year, setYear] =useState([]);
  const [sessions, setSession] =useState([]);
  const [ID, setID] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [dataSession, setDataSession]=useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [selectSessionValue, setselectSessionValue] = useState();
  const userID = userDetails.userID;
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};

  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };


    useEffect(()=>{
        fetchGraduation(userID)
   },[])

   const fetchGraduation = async () => {
    try {  
        const userData= {userID:userDetails.userID}
        
         axios.get(`${baseUrl}savegraduationyear/${userID}`).then(response =>
         {
              //console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItemsSession();
        //console.log(response);
        const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
        setDataSession(customHeadings);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []); 
  const selectSessionOnChange = (selectedOption) => 
  {
    setFieldValue('session', selectedOption.value);
    setselectSessionValue(selectedOption);
  }


  function handleModalSubmit(e)
  {
      e.preventDefault();
      const path ="graduationupdate";
      const userData ={
                  id :ID,
                  year : e.target.year.value,
                  sessionID : selectSessionValue.value,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          } 
          
      dispatches(saveAction(userData,path,navigate));
      fetchGraduation(userID)
      dispatch({type:'basicModal'})
  }
  const handleInputChange = (e) => {
      setYear(e.target.value);
  };
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deletegraduation/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            fetchGraduation(userID)
            dispatch({type:'smallModal'})
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }


  return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={1}></Col>
          <Col lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>List Graduation Year</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>Year</strong></th>
                      <th><strong>SessionID</strong></th>
                      <th><strong>Session</strong></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						            <td>{++index}</td>
                        <td>{item.year}</td>
                        <td>{item.sessionID}</td>
                        <td>{item.session}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'basicModal'}, setID(item.id), setYear(item.year), setSession(item.session))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setYear(item.year), setSession(item.session))}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Graduation Year</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                
                                  <div className="card-body">
                                      <div className="row">
                              
                                    
                                          <div className="col-sm-6 m-b30">
                                              <label className="form-label">Year </label>
                                              <input type="text" id="year" name="year" onChange={handleInputChange} value={year} className="form-control" required />
                                          </div>
                                        
                                            <div className="col-sm-6 m-b30">
                                                  <label className="form-label">Session [{sessions}] </label>
                                                  <Select options={dataSession}  className="custom-react-select" value={selectSessionValue} onChange={selectSessionOnChange} isSearchable={true} required />                                    
                                            </div>
                                          
                                      </div>
                                  </div>
                                  
                            
                

                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete Graudation Year</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {year}  of {sessions} session information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default GraduationIndex;
