import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Index = () => {
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    //var headers   = {'Content-Type': 'multipart/form-data', 'Authorization':'Token ' + `${userDetails.token}`};
    const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    const baseUrl = process.env.REACT_APP_BASE_URL;  
    const [dataResult, setDataResult]=useState([]);
    function onSearch(e)
    {
        e.preventDefault();
        const matricno = e.target.matricno.value;
        const  userData = { matricno: matricno,
                            userID:userDetails.userID,
                            createdBy:userDetails.email
                          }
         axios.post(`${baseUrl}generatetranscript/`,userData,{headers}).then(response => 
          {              
              if(response.data.statuscode=== 200)
              {
                setDataResult(response.data.data);
                sessionStorage.removeItem("matricno");
                sessionStorage.setItem('matricno', matricno);
                navigate('/displayResult'); 
              }
              else
              {
                toast.error(response.data.message);           
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer)   
              }
              }) .catch((error) => {
              if (error.response) {
                 
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
             
           
    }


    return(
        <>
          <ToastContainer position="top-right"></ToastContainer>
            <div className="container-fluid">
                <div className="row">
                   <div className="col-xl-2 col-lg-8">
                    </div>
                    <div className="col-xl-6 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Generate Transcript</h6>
                            </div>
                            <form className="profile-form"  onSubmit={onSearch}>
                                <div className="card-body">
                                    <div className="row">
                        
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Matric.No </label>
                                            <input type="text" className="form-control" id="matricno" name="matricno" placeholder="Matriculation Number" required/>
                                        </div>

                                     
                                      
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Generate Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;