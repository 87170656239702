import React,{useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Rain from './../../../images/rainbow.gif';
import DashboardIndex from '../../../jsx/components/documee/Reports/Views/Dashboard'
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import { IMAGES, SVGICON } from '../../constant/theme';

import ProjectOverviewTab from './elements/ProjectOverviewTab';
import ToDoList from './elements/ToDoList';
import ActiveProjects from './elements/ActiveProjects';
import RunningSwiper from './RunningSwiper';
import UpcomingBlog from './elements/UpcomingBlog';
import AllProjectBlog from './elements/AllProjectBlog';

const RevenueChart = loadable(() =>
	pMinDelay(import("./elements/RevenueChart"), 1000)
);
const ExpensesBarChart = loadable(() =>
	pMinDelay(import("./elements/ExpensesBarChart"), 1000)
);
const TotalDeposit = loadable(() =>
	pMinDelay(import("./elements/TotalDeposit"), 1000)
);
const MyProgressChart = loadable(() =>
	pMinDelay(import("./elements/MyProgressChart"), 1000)
);

//import { fetchAll } from '../documee/Lists/TestAll';

const Home = () => {
	const userDetails = JSON.parse(localStorage.getItem('userDetails'));
   
	const { changeBackground } = useContext(ThemeContext);	
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);

 
	return(
		<>			
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-9 col-xxl-12">
						<div className="row">
					  
							<div className="col-xl-12">
								<div className="row">  
									<div className="col-xl-6">
										<div className="row">
											
											<div className="col-xl-6 col-sm-6">
												<div className="card card bg-success">
													<div className="card-body depostit-card">
														<div className="depostit-card-media d-flex justify-content-between style-1">
															<div>
																<h6>Faculties</h6>
																<h3 className="text-white">{userDetails.faculty}</h3>
															</div>
															<div className="icon-box bg-secondary">
																{SVGICON.Shiled}
															</div>
														</div>
														<div className="progress-box mt-0">
															<div className="d-flex justify-content-between">
																{/* <p className="mb-0">Complete</p> */}
																<p className="mb-0"></p>
															</div>
															{/* <div className="progress">
																<div className="progress-bar bg-secondary" style={{width:"50%", height:"5px", borderRadius:"4px"}}></div>
															</div> */}
														</div>
													</div>
												</div>	
											</div>
											<div className="col-xl-6 col-sm-6">
												<div className="card same-card card bg-primary">
													<div className="card-body depostit-card p-0">
														<div className="depostit-card-media d-flex justify-content-between pb-0">
															<div>
																<h6  className="text-white">Departments</h6>
																<h3  className="text-white">{userDetails.department}</h3>
															</div>
															<div className="icon-box bg-primary">
																{SVGICON.Shiled}
															</div>
														</div>															
														<TotalDeposit />
													</div>
												</div>
											</div>

											
										</div>	
									
									</div>
									

									{/* second part */}
									<div className="col-xl-6">
										<div className="row">
											{/* <div className="col-xl-6 col-sm-6">
												<div className="card bg-success text-white">
													<div className="card-header border-0 flex-wrap">
														<div className="revenue-date">
															<span>No. of Students</span>
															<h4 className="text-white">{userDetails.student}</h4>
														</div>
														<div className="avatar-list avatar-list-stacked me-2">
															<img src={IMAGES.contact5} className="avatar rounded-circle" alt="" />{" "}
															<img src={IMAGES.contact6} className="avatar rounded-circle" alt="" />{" "}
															<span className="avatar rounded-circle bg-white">25+</span>
														</div>
														
													</div>
													<div className="card-body pb-0 custome-tooltip d-flex align-items-center">															
														<RevenueChart />
														<div>
															{SVGICON.WhiteArrowUp}
															<span className="d-block font-w600">4%</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-xl-6 col-sm-6">
												<div className="card bg-secondary text-white">
													<div className="card-header border-0">
														<div className="revenue-date">
															<span className="text-black">Transcript Proceed</span>
															<h4 className="text-black">10</h4>
														</div>
														<div className="avatar-list avatar-list-stacked me-2">
															<span className="avatar rounded-circle bg-white">
																<Link to={"#"}>
																	{SVGICON.PlusSymbal}
																</Link>
															</span>
														</div>
														
													</div>
													<div className="card-body pb-0 custome-tooltip d-flex align-items-center">
														<ExpensesBarChart />
														<div>
															{SVGICON.DarkArrowUp}
															<span className="d-block font-w600 text-black">45%</span>
														</div>
													</div>
												</div>
											</div> */}
											<div className="col-xl-6 col-sm-6">
												<div className="card card bg-info">
													<div className="card-body depostit-card">
														<div className="depostit-card-media d-flex justify-content-between style-1">
															<div>
																<h6>No of Student</h6>
																<h3  className="text-white">{userDetails.student}</h3>
															</div>
															<div className="icon-box bg-secondary">
																{SVGICON.Shiled}
															</div>
														</div>
														<div className="progress-box mt-0">
															<div className="d-flex justify-content-between">
																{/* <p className="mb-0">Complete</p> */}
																<p className="mb-0"></p>
															</div>
															{/* <div className="progress">
																<div className="progress-bar bg-secondary" style={{width:"50%", height:"5px", borderRadius:"4px"}}></div>
															</div> */}
														</div>
													</div>
												</div>	
											</div>
											<div className="col-xl-6 col-sm-6">
												<div className="card same-card card bg-warning">
													<div className="card-body depostit-card p-0">
														<div className="depostit-card-media d-flex justify-content-between pb-0">
															<div>
																<h6  className="text-black">Pending Request</h6>
																<h3  className="text-white">0</h3>
															</div>
															<div className="icon-box bg-primary">
																{SVGICON.Shiled}
															</div>
														</div>															
														<TotalDeposit />
													</div>
												</div>
											</div>
										</div>	
									</div>

									
							
								</div>									
							</div>
						</div>
					</div>
					<DashboardIndex />
				</div>			
			</div>
		</>
	)
}
export default Home;