//import {format} from 'date-fns';
import { ColumnFilter } from '../ColumnFilter';

export const COLUMNS = [
	{
		Header : 'id',
		Footer : 'id',
		accessor: 'id',
		Filter: ColumnFilter,
	},
	{
		Header : 'School Name',
		Footer : 'School Name',
		accessor: 'shortname',
		Filter: ColumnFilter,
	},
	{
		Header : 'name',
		Footer : 'name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Action',
		Footer : 'Action',
		
	},

	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Department',
		Footer : 'Department',
		columns: [
			{
				Header : 'Department',
				Footer : 'Department',
				accessor: 'department',
			}
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Department',
				Footer : 'Department',
				accessor: 'department',
			},
			{
				Header : 'ID',
				Footer : 'ID',
				accessor: 'id',
			},
		]
	},
]