import React,{useState, useEffect,Fragment,useReducer} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemUser } from '../../Lists/Users';
import Select from 'react-select';
import  { fetchItemsRoles} from '../../Lists/Roles';
import  { fetchItemUserRole} from '../../Lists/UserRole';
import  { getItemUserRole} from '../../Lists/UserRole';

import PageTitle from "../../../../layouts/PageTitle";
import {
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";

const examTopper = [
  {id:'111', color:'success', status:'Successful'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [dataUsers, setDataUsers] =useState([]);
    const [dataRoles, setDataRoles] =useState([]);
    const [dataUserRoles, setDataUserRoles] =useState([]);
    const [dataGetUserRoles, setDataGetUserRoles] =useState([]);
    const [userName, setUserName] =useState([]);
    const [names, setNames] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [fieldValue, setFieldValue]=useState();
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const [selectRoleValue, setselectRoleValue] = useState();

      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };


const selectRoleOnChange = (selectedOption) => 
{
  setFieldValue('role', selectedOption.value);
  setselectRoleValue(selectedOption);
};
      
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="saveuserrole";
        const userData ={
                    
                    username: userName,
                    roleID : selectRoleValue.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
    }



   
useEffect(()=>{
        const fetchUserData = async (userID) => {
            try 
            {
               const response = await fetchItemUser(userID);
               //console.log(response);
               setDataUsers(response.data);
            } catch (error) {
              setError(error.message);
            }
          };
    fetchUserData(userID)
},[]);
        
useEffect(()=>{
    const fetchRoleData = async (userID) => {
        try 
        {
           const response = await fetchItemsRoles(userID);
           const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
           setDataRoles(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
fetchRoleData(userID)
},[]);

useEffect(()=>{
    const fetchUserRoleData = async (userID) => {
        try 
        {
           const response = await fetchItemUserRole(userID);
           setDataUserRoles(response.data);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchUserRoleData(userID)
},[]);

useEffect(()=>{
    const getUserRoleData = async (userName) => {
        try 
        {
          
           const response = await getItemUserRole(userName);
           setDataGetUserRoles(response.data);
        } catch (error) {
          setError(error.message);
        }
      };
      getUserRoleData(userName)
},[userName]);




    return(
        <>

            <Fragment>
            {/* <PageTitle activeMenu="Information" motherMenu="School" /> */}
            <PageTitle activeMenu="Information" motherMenu="Assign Role" />
            <div className="container-fluid">
                  <div className="row">
                    <div className="col-xl-1 col-lg-6">
                    </div>
                    <Col lg={12}>
                    <Card>
                    <Card.Header>
                        <Card.Title>Assign Role</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive striped security='1'> 
                        <thead>
                            <tr>
                                    <th><strong>NO.</strong></th>
                                    <th><strong>Username</strong></th>
                                    <th><strong>Firstname</strong></th>
                                    <th><strong>Surname</strong></th>
                                    <th><strong>Email</strong></th>
                                    <th><strong>Phone</strong></th>
                                    <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataUsers.map((item, index)=>(
                            <tr key={index}>
                               
                                
                                <td>{++index}</td>
                                <td>{item.username}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>
                                <div className="d-flex">
                                    <Link
                                    to={"#"}
                                    onClick={() => dispatch({type:'basicModal'}, setUserName(item.email)) }
                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                    >
                                    <i className="fas fa-pencil-alt"></i>
                                    </Link>
                                    <Link
                                    onClick={() => dispatch({type:'largeModal'}, setUserName(item.email), setNames(item.first_name+ ' '+item.last_name))}
                                    to={"#"}
                                    className="btn btn-success shadow btn-xs sharp"
                                    >
                                    <i className="fa fa-list"></i>
                                    </Link>
                                </div>
                                </td>
                            </tr>
                            ))}
                            
                        </tbody>
                        </Table>
                    </Card.Body>
                    </Card>
                    </Col>     
                  </div>
             </div>

             {/* <!-- Modal --> */}
		    <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
                <form className="profile-form" onSubmit={handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Assign Role</Modal.Title>
                                    <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                                </Modal.Header>
                                <Modal.Body>
                                    
                            
                                    <div className="card-body">
                                        <div className="row">              
                                                <div className="col-sm-8 m-b30">
                                                        <label className="form-label">Roles</label>
                                                        <Select options={dataRoles} value={selectRoleValue}  onChange={selectRoleOnChange} className="custom-react-select" 
                                                        isSearchable={true}
                                                        />
                                                </div>
                                        
                                            
                                        </div>
                                    </div>
                                    
                            
                                

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                                    <button className="btn btn-primary">Assign Role</button>
                                </Modal.Footer>
                </form>   
			</Modal>
            {/* Delete Modal */}
            <Modal className="fade" show={state.largeModal} onHide={()=>dispatch({type:'largeModal'})}>
                <Modal.Header>
                    <Modal.Title>{names}'s Role</Modal.Title>
                    <Button
                    variant=""
                    className="btn-close"
                    onClick={() => dispatch({type:'largeModal'})}
                    >
                    
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    
                <Card>
                    <Card.Header>
                        <Card.Title>Role</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive striped security='1'> 
                        <thead>
                            <tr>
                                    <th><strong>NO.</strong></th>
                                    <th><strong>Username</strong></th>
                                    <th><strong>Role</strong></th>
                                  
                                    
                                  
                            </tr>
                        </thead>
                        <tbody>
                            {dataGetUserRoles.map((item, index)=>(
                            <tr key={index}>
                               
                                
                                <td>{++index}</td>
                                <td>{item.username}</td>
                                <td>{item.name}</td>
                             
                              
                               
                            </tr>
                            ))}
                            
                        </tbody>
                        </Table>
                    </Card.Body>
                    </Card>

                </Modal.Body>
               
            </Modal>
           </Fragment>


       
            




        </>
    )
}
export default Index;