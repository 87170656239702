import React, {useState, useEffect} from "react"
import axios from 'axios';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
  
  } from "react-bootstrap";
  import  { fetchItemsResult  } from '../../Lists/Results';
  
  const Results = ({matricno, level,semester,session,department}) => 
  {
    const baseUrl = process.env.REACT_APP_BASE_URL;  
    const [error, setError] = useState(null);
    const [dataResults, setDataResults] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    useEffect(()=>{
        const fetchResults = async () => {
            try {
              
              
                const userData= {matricno:matricno,
                                 levelID:level, 
                                 semesterID:semester, 
                                 sessionID:session,
                                 userID:userDetails.userID}

                ///console.log(userData);
                 axios.post(`${baseUrl}getresultofstudent/`,userData,{headers}).then(response =>
                 {
                      //console.log(response);
                      if(response.data.statuscode===200)
                      {
                        setDataResults(response.data.data);
                      }
                      else 
                      {
                        throw new Error('Network response was not ok');
                      }
                 }).catch((error) => {
                  if (error.response) {
                      console.log(error.response);
                      console.log("server responded");
                  } else if (error.request) {
                      console.log("network error");
                  } else {
                      console.log(error);
                  }
                  });
               
              } catch (error) {
                throw new Error(`Error fetching items: ${error.message}`);
              }
          };
          fetchResults(userID)
     },[userID])



    return(
        <>
     
           
                <div className="col-xl-1 col-lg-6">
                </div>
                <Col lg={6}>
                <Card>
                <Card.Header>
                    <Card.Title>Student Result [{matricno}][{level} Level]</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table responsive striped security='1'> 
                    <thead>
                        <tr>
                        <th><strong>NO.</strong></th>
                        <th><strong>Coursecode</strong></th>
                        <th><strong>Score</strong></th>
                        <th><strong>Point</strong></th>
                        <th>Grade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataResults.map((item, index)=>(
                        <tr key={index}>
                        
                            <td>{++index}</td>
                            <td>{item.coursecode}</td>
                            <td>{item.score}</td>
                            <td>{item.point}</td>
                            <td>{item.grade }</td>
                        </tr>
                        ))}
                        
                    </tbody>
                    </Table>
                </Card.Body>
                </Card>
                </Col>     
           
        

        </>
    );
}

export default Results