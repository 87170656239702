import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import  { fetchItemsRoles} from '../../Lists/Roles';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import UserList from '../../Reports/Views/Users'

const Index = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [error, setError] = useState(null);
    const [dataRoles, setDataRoles] = useState([]);
    const dispatches = useDispatch();
    const navigate = useNavigate();

    function handleSubmit(e) 
    {
      e.preventDefault();
      //const username = e.target.email.value.split("@")[0];
      const path ="saveusers";
      const userData ={
                   first_name : e.target.first_name.value,
                   last_name : e.target.last_name.value,
                   name : e.target.last_name.value + ' '+ e.target.first_name.value,
                   phone : e.target.phone.value,
                   email : e.target.email.value,
                   password:e.target.phone.value,
                   ipaddress:"12.45.78.86",
                   username : e.target.email.value.split("@")[0],
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
      dispatches(saveAction(userData,path,navigate));
    }

   
    // useEffect(() => {
    //     const fetchData = async (userID) => {
    //       try {
    //         const response = await fetchItemsRoles(userID);
    //         //console.log(response);
    //         const customHeadings = response.data.map(item=>({
    //             "value": item.id,
    //             "label": item.name        
    //          }))
    //         setDataRoles(customHeadings);
    //       } catch (error) {
    //         setError(error.message);
    //       }
    //     };
    
    //     fetchData(userID);
    //   }, []); 
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                   
                    <div className="col-xl-12 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Users </h6>
                            </div>
                            <form onSubmit={handleSubmit} className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                        
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Surname </label>
                                            <input type="text" className="form-control" name="last_name" id="last_name" placeholder="Surname" required/>
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">First Name </label>
                                            <input type="text" className="form-control" placeholder="First Name" id="first_name" name="first_name" required />
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Other name </label>
                                            <input type="text" className="form-control" placeholder="Othername" id="othername" name="othername" />
                                        </div>
                                       
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Email </label>
                                            <input type="email" className="form-control" placeholder="Email Address" name="email" id="email" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Phone </label>
                                            <input type="text" className="form-control" placeholder="Phone Number" name="phone" id="phone" />
                                        </div>

                                        {/* <div className="col-sm-4 m-b30">
                                            <label className="form-label">Roles</label>
                                            <Select options={dataRoles}  className="custom-react-select" 
                                            isSearchable={true}
                                        />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add User</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                         <UserList /> 
                                              
                </div>
            </div>
        </>
    )
}
export default Index;