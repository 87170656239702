//import {format} from 'date-fns';
import { ColumnFilter } from '../ColumnFilter';



function dateFormat(value){
	let objectDate = new Date(value);
	let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
	let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
	let year = objectDate.getFullYear();
	
	return  day + "/" + month + "/" + year;	 
	
}



export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
	},
	{
		Header : 'School Name',
		Footer : 'School Name',
		accessor: 'shortname',
		Filter: ColumnFilter,
	},
	{
		Header : 'Semester',
		Footer : 'Semester',
		accessor: 'semester',
		Filter: ColumnFilter,
	},
	

	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Semester',
		Footer : 'Semester',
		columns: [
			{
				Header : 'Semester',
				Footer : 'Semester',
				accessor: 'semester',
			}
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Semester',
				Footer : 'Semester',
				accessor: 'semester',
			},
			{
				Header : 'ID',
				Footer : 'ID',
				accessor: 'id',
			},
		]
	},
]