import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { IMAGES } from '../../../../../constant/theme';

const inputName = [
    { label:'School Name', value:'Federal University Of Technology Akure' },
    { label:'School Address', value:'PMB 704, The Federal University of Technology Akure, Ondo State' },
];



const options2 = [
    { value: '1', label: 'Select' },
    { value: '2', label: 'Male' },
    { value: '3', label: 'Female' },
    { value: '4', label: 'Other' }
]
const options3 = [
    { value: '1', label: 'Nigeria' },
    { value: '2', label: 'US' },
    { value: '3', label: 'China' },
    { value: '4', label: 'India' }
]

const EditSchoolInfo = () => {
   // const [selectOption , setSelectOption] = useState('Gender');

   const [file, setFile] = useState();
   function handleChange(e) {
       console.log(e.target.files);
       setFile(URL.createObjectURL(e.target.files[0]));
   }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="clearfix">
                            <div className="card card-bx profile-card author-profile m-b30">
                                <div className="card-body">
                                    <div className="p-5">
                                        <div className="author-profile">
                                            <div className="author-media">
                                                 {file &&  <img src={file} alt="" /> }  {!file && <img src={IMAGES.User} alt="" /> }
                                              
                                                <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                    <input type="file" onChange={handleChange}  className="update-flie" />
                                                    <i className="fa fa-camera"></i>
                                                </div>
                                                
                                              
                                            </div>
                                            <div className="author-info">
                                                <h6 className="title">School Logo</h6>
                                                <span>FUTA</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="info-list">
                                        <ul>
                                            <li><Link to={"/app-profile"}>Models</Link><span>36</span></li>
                                            <li><Link to={"/uc-lightgallery"}>Gallery</Link><span>3</span></li>
                                            <li><Link to={"/app-profile"}>Lessons</Link><span>1</span></li>
                                        </ul>
                                    </div> */}
                                </div>
                                {/* <div className="card-footer">
                                    <div className="input-group mb-3">
                                        <div className="form-control rounded text-center bg-white">Portfolio</div>
                                    </div>
                                    <div className="input-group">
                                        <a href="https://www.dexignzone.com/" target="blank" className="form-control text-primary rounded text-start bg-white">https://www.dexignzone.com/</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">School Account setup</h6>
                            </div>
                            <form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                                        { inputName.map((item, ind)=>(
                                            <div className="col-sm-12 m-b30" key={ind}>
                                                <label className="form-label">{item.label}</label>
                                                <input type="text" className="form-control" defaultValue={item.value}  />
                                            </div>
                                        ))}
                                        
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" defaultValue="+234 805 6789 9000" />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Email address</label>
                                            <input type="text" className="form-control" defaultValue="info@futa.edu.ng" />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={options3}  className="custom-react-select" 
                                                defaultValue={options3[0]}
                                                isSearchable={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">City</label> 
                                            <input type="text" className="form-control" defaultValue="Akure" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">UPDATE</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditSchoolInfo;