import React,{useState, useEffect} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemsSession } from '../../Lists/Session';
import SemesterIndex from '../../Reports/Views/Semester/'
const CreateDepartment = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [dataSession, setDataSession]=useState([]);
    const [error, setError] = useState(null);
    const [selectSessionValue, setselectSessionValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savesemester";
        const userData ={
                    
                    name : e.target.semester.value,
                    sessionID:selectSessionValue.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
    }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetchItemsSession();
            //console.log(response);
            const customHeadings = response.data.map(item=>({
                "value": item.id,
                "label": item.name        
             }))
            setDataSession(customHeadings);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchData();
      }, []); 
    
      const selectSessionOnChange = (selectedOption) => 
      {
        setFieldValue('session', selectedOption.value);
        setselectSessionValue(selectedOption);
      }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-6">
                 
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Semester </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                            
                                    
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Semester Name</label>
                                            <input type="text" className="form-control" name='semester' id='semester' placeholder="First Semester/Rain" required />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                             <label className="form-label">Session </label>
                                             <Select options={dataSession}  className="custom-react-select" 
                                                            placeholder="Session"
                                                            value={selectSessionValue} onChange={selectSessionOnChange}
                                                            isSearchable={true} />                                    
                                           </div>
                                      </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Semester</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <SemesterIndex />
                </div>
            </div>
        </>
    )
}
export default CreateDepartment;