import React,{useState, useEffect,Fragment,useReducer} from 'react';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemsLevel } from '../../Lists/Level';
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import  { fetchItemsSession } from '../../Lists/Session';
import LevelIndex from '../../Reports/Views/Level/'
const examTopper = [
  {id:'111', color:'success', status:'Successful'},
//   {id:'112', color:'danger', status:'Canceled'},
//   {id:'113', color:'warning', status:'Pending'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [dataLevel, setDataLevel] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const [dataSession, setDataSession]=useState([]);
    const [selectSessionValue, setselectSessionValue] = useState();
    const [checked, setChecked] = useState(examTopper);
    const [fieldValue, setFieldValue]=useState();
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };

    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savelevel";
        const userData ={
                    
                    name : e.target.level.value,
                    sessionID : selectSessionValue.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
        window.location.reload();
    }
useEffect(()=>{
        const fetchLevelData = async (userID) => {
            try 
            {
               const response = await fetchItemsLevel(userID);
               setDataLevel(response.data);
            } catch (error) {
              setError(error.message);
            }
          };
    fetchLevelData(userID)
},[dataLevel])
        


useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItemsSession();
        //console.log(response);
        const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
        setDataSession(customHeadings);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []); 

  const selectSessionOnChange = (selectedOption) => 
  {
    setFieldValue('session', selectedOption.value);
    setselectSessionValue(selectedOption);
  }

    return(
        <>
          <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-6">
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Level </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Level</label>
                                            <input type="text" className="form-control" name='level' id='level' placeholder="100" required />
                                        </div>
                                            <div className="col-sm-4 m-b30">
                                                <label className="form-label">Session </label>
                                                <Select options={dataSession}  className="custom-react-select" 
                                                                placeholder="Session"
                                                                value={selectSessionValue} onChange={selectSessionOnChange}
                                                                isSearchable={true} required />                                    
                                            </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Level</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <LevelIndex />
                </div>
          </div>
           



       
            




        </>
    )
}
export default Index;