import React, {useState, useEffect} from "react"
import axios from 'axios';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
  
  } from "react-bootstrap";
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  const UserList = () => 
  {
    const baseUrl = process.env.REACT_APP_BASE_URL;  
    const [error, setError] = useState(null);
    const [data, setData] =useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};
    useEffect(()=>{
        const fetchResults = async () => {
            try {  
                const userData= {userID:userDetails.userID}
                 //console.log(userData);
                 axios.get(`${baseUrl}saveusers/${userID}`).then(response =>
                 {
                      //console.log(response);
                      if(response.data.statuscode===200)
                      {
                        setData(response.data.data);
                      }
                      else 
                      {
                        throw new Error('Network response was not ok');
                      }
                 }).catch((error) => {
                  if (error.response) {
                     // console.log(error.response);
                      //console.log("server responded");
                  } else if (error.request) {
                     // console.log("network error");
                  } else {
                      //console.log(error);
                  }
                  });
               
              } catch (error) {
                throw new Error(`Error fetching items: ${error.message}`);
              }
          };
          fetchResults(userID)
     },[userID])

     const deleteRecord = (id) => 
     {
         
          if(window.confirm('Are you sure you want to cancel this record'))
          {
                axios.delete(`${baseUrl}deleteuser/${id}`,{headers}).then(response =>
                {          
                  toast.success(error.response.data.error)
                }).catch((error) => {
                    if (error.response) {
                        //console.log(error.response.data.statuscode);
                        toast.error(error.response.data.error)
                    } else if (error.request) {
                        //console.log("network error");
                    } else {
                        //console.log(error);
                    }
                    });
             
              
               //setTimeout(() => loaddata(matricno), 500)
               //window.location.reload();
          };
     }

    return(
        <>
     
              <ToastContainer position="top-center"></ToastContainer>
                <div className="col-xl-1 col-lg-6">
                </div>
                <Col lg={6}>
                <Card>
                <Card.Header>
                    <Card.Title>Users Record</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table responsive striped security='1'> 
                    <thead>
                        <tr>
                        <th><strong>NO.</strong></th>
                        <th><strong>Name</strong></th>
                        <th><strong>Email</strong></th>
                        <th><strong>Phone</strong></th>
                        <th><strong>Username</strong></th>
                        <th><strong>Action</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index)=>(
                        <tr key={index}>
                        
                            <td>{++index}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.username }</td>
                            <td>
                            <Button style={{ background:'red' }}
                                                               
                                    onClick={() =>deleteRecord(item.id)}
                                    size="sm"
                                    >
                                    Delete
                             </Button>
                            </td>
                        </tr>
                        ))}
                        
                    </tbody>
                    </Table>
                </Card.Body>
                </Card>
                </Col>     
           
        

        </>
    );
}

export default UserList