import React, { Fragment, useState, useReducer } from "react";
import PageTitle from "../../../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

/// imge
import avatar1 from "../../../../../../images/avatar/1.jpg";
import { Link } from "react-router-dom";

const examTopper = [
  {id:'111', color:'success', status:'Successful'},
//   {id:'112', color:'danger', status:'Canceled'},
//   {id:'113', color:'warning', status:'Pending'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

  const dept="Computer Science";
  return (
    <Fragment>
      <PageTitle activeMenu="Information" motherMenu="School" />
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Edit Semester</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width50 ">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            required=""
                            onClick={()=>handleCheckedAll(unchecked)}  
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>NO.</strong>
                      </th>
                      <th>
                        <strong>Semester</strong>
                      </th>
                     
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checked.map((item, index)=>(
                      <tr key={index}>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`exam-${item.id}`}
                              checked={item.inputchecked}
                              onChange={()=>handleChecked(item.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`exam-${item.id}`}
                            ></label>
                          </div>
                        </td>
                        
						<td>1</td>
                        <td>First Semester</td>
                       
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
							  onClick={() => dispatch({type:'basicModal'})}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'})}
                              to={"#"}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          
         
        </Row>
      </div>

           {/* <!-- Modal --> */}
			<Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
							<Modal.Header>
								<Modal.Title>Edit Semester</Modal.Title>
								<Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
							</Modal.Header>
							<Modal.Body>
								
							<form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                            
                                  
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Semester</label>
                                            <input type="text" className="form-control" defaultValue={"First Semester "} placeholder="Semester" />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                
                            </form>
							

							</Modal.Body>
							<Modal.Footer>
								<Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
								<Button variant="primary">Update Semester </Button>
							</Modal.Footer>
						</Modal>

         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
			<Modal.Header>
				<Modal.Title>Delete Semester</Modal.Title>
				<Button
				variant=""
				className="btn-close"
				onClick={() => dispatch({type:'smallModal'})}
				>
				
				</Button>
			</Modal.Header>
			<Modal.Body>
				<span style={{ color:'red' }}>Are you sure you want to delete this semester information?</span>
			</Modal.Body>
			<Modal.Footer>
				<Button
				variant="danger light"
				onClick={() => dispatch({type:'smallModal'})}
				>
				Close
				</Button>
				<Button variant="danger">Delete</Button>
			</Modal.Footer>
        </Modal>




    </Fragment>




  );

};

export default Index;
