import React, { Fragment, useState, useReducer } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,
} from "react-bootstrap";


import { Link } from "react-router-dom";
import Select  from "react-select";
import DatePicker from "react-datepicker";

const examTopper = [
  {id:'111', color:'success', status:'Successful'},
//   {id:'112', color:'danger', status:'Canceled'},
//   {id:'113', color:'warning', status:'Pending'},
];


const options1 = [
    { value: '1', label: 'Computer Science' },
    { value: '2', label: 'Mathematics' },

]

const options2 = [
    { value: '1', label: 'Cyber Security' },
    { value: '2', label: 'Statistics' },

]

const options3 = [
    { value: '1', label: '2001' },
    { value: '2', label: '2002' },

];

const options4 = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },

]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [startDate2, setStartDate2] = useState(new Date());
  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };

  const dept="Computer Science";
  return (
    <Fragment>
      <PageTitle activeMenu="Information" motherMenu="School" />
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>View/Edit Students</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width50 ">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            required=""
                            onClick={()=>handleCheckedAll(unchecked)}  
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>NO.</strong>
                      </th>
                      <th>
                        <strong>MatriNo.</strong>
                      </th>
                      <th>
                        <strong>Surname </strong>
                      </th>
                      <th>
                        <strong>Firstname </strong>
                      </th>
                      <th>
                        <strong>Othername </strong>
                      </th>
                      <th>
                        <strong>Email </strong>
                      </th>
                      <th>
                        <strong>Phone </strong>
                      </th>
                      <th>
                        <strong>Birth Date </strong>
                      </th>
                      <th>
                        <strong>Department</strong>
                      </th>
                      <th>
                        <strong>Grad. Year</strong>
                      </th>
                     
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checked.map((item, index)=>(
                      <tr key={index}>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`exam-${item.id}`}
                              checked={item.inputchecked}
                              onChange={()=>handleChecked(item.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`exam-${item.id}`}
                            ></label>
                          </div>
                         </td>
                        
					            	<td>1</td>
                        <td>CSC/19/7000</td>
                        <td>James</td>
                        <td>Harrison</td>
                        <td>Tayo</td>
                        <td>james@gmail.com</td>
                        <td>+234 803 7894 5623</td>
                        <td>12-03-1990</td>
                        <td>Computer Science</td>
                        <td>2009</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
							                onClick={() => dispatch({type:'largeModal'})}
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'})}
                              to={"#"}
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      
           
                         </tr>
                         ))}
                        
                  
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          
         
        </Row>
      </div>

           {/* <!-- Modal --> */}
           <Modal className="fade bd-example-modal-lg" show={state.largeModal} size="lg" onHide={()=>dispatch({type:'largeModal'})}>
            <Modal.Header>
              <Modal.Title>Edit Student Information</Modal.Title>
              <Button variant="" className="btn-close" onClick={() => dispatch({type:'largeModal'})}></Button>
            </Modal.Header>
							<Modal.Body>
								
                            <form className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-sm-4 m-b30">
                                            <label className="form-label">Matric No. </label>
                                            <input type="text" className="form-control" placeholder="Student Matriculation No" required/>
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Surname </label>
                                            <input type="text" className="form-control" placeholder="Surname" required/>
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">First Name </label>
                                            <input type="text" className="form-control" placeholder="First Name" required />
                                        </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Other name </label>
                                            <input type="text" className="form-control" placeholder="Othername" />
                                        </div>
                                       
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Email </label>
                                            <input type="email" className="form-control" placeholder="Email Address" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Phone </label>
                                            <input type="text" className="form-control" placeholder="Phone Number" />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Birth</label>                                              
                                            <DatePicker 
                                                className="form-control"
                                                selected={startDate2} 
                                                onChange={(date) => setStartDate2(date)} 
                                            />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Gender</label>
                                            <Select options={options4}  className="custom-react-select" 
                                            defaultValue={options4[0]}
                                            isSearchable={true}
                                        /></div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Department</label>
                                            <Select options={options1}  className="custom-react-select" 
                                            defaultValue={options1[0]}
                                            isSearchable={true}
                                        />
                                         </div>
                                        {/* <div className="col-sm-4 m-b30">
                                            <label className="form-label">Programme</label>
                                            <Select options={options2}  className="custom-react-select" 
                                            defaultValue={options2[0]}
                                            isSearchable={true}
                                        />
                                         </div> */}
                                           <div className="col-sm-4 m-b30">
                                                <label className="form-label">Year of Graduation</label>
                                                <Select options={options3}  className="custom-react-select" 
                                                defaultValue={options3[0]}
                                                isSearchable={true}
                                            />
                                            </div>
                                        
                                    </div>
                                </div>
                                
                               
                                
                            </form>
							

							</Modal.Body>
							<Modal.Footer>
								<Button  variant="danger light" onClick={() => dispatch({type:'largeModal'})}>Close</Button>
								<Button variant="primary">Update Record </Button>
							</Modal.Footer>
						</Modal>

         {/* Delete Modal */}
      <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
			<Modal.Header>
				<Modal.Title>Delete Student</Modal.Title>
				<Button
				variant=""
				className="btn-close"
				onClick={() => dispatch({type:'smallModal'})}
				>
				
				</Button>
			</Modal.Header>
			<Modal.Body>
				<span style={{ color:'red' }}>Are you sure you want to delete this student information?</span>
			</Modal.Body>
			<Modal.Footer>
				<Button
				variant="danger light"
				onClick={() => dispatch({type:'smallModal'})}
				>
				Close
				</Button>
				<Button variant="danger">Delete</Button>
			</Modal.Footer>
        </Modal>




    </Fragment>




  );

};

export default Index;
