
import { ColumnFilter } from '../ColumnFilter';


export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
	},
	{
		Header : 'Surname',
		Footer : 'Surname',
		accessor: 'surname',
		Filter: ColumnFilter,
	},
	{
		Header : 'First Name',
		Footer : 'First Name',
		accessor: 'firstname',
		Filter: ColumnFilter,
	},
    {
		Header : 'Othername',
		Footer : 'Othername',
		accessor: 'othername',
		Filter: ColumnFilter,
	},
	
    {
		Header : 'Email',
		Footer : 'Email',
		accessor: 'email',
		Filter: ColumnFilter,
	},

    {
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},

    {
		Header : 'Role',
		Footer : 'Role',
		accessor: 'role',
		Filter: ColumnFilter,
	},

  

    

	
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Surname',
		Footer : 'Surname',
		columns: [
			{
				Header : 'Firstname',
				Footer : 'Firstname',
				accessor: 'firstname',
			}
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Email',
				Footer : 'Email',
				accessor: 'email',
			},
			{
				Header : 'Email',
				Footer : 'Email',
				accessor: 'email',
			},
		]
	},
]