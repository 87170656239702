import React, { Fragment, useState, useEffect } from "react";
import Highlight from "react-highlight";
import {
  Row,
  Col,
  Card,
  Tab, Nav
} from "react-bootstrap";
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import  { fetchItemsApiKey } from '../../Lists/ApiKey';
import  axios  from "axios";
const Index = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const [activeLink ,setActiveLink] = useState(0);
  const [dataApikey, setDataApikey] =useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const apikey =userDetails.apikey;
  const userID =userDetails.id;
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  function handleSubmit(e) 
  {
      e.preventDefault();
      const path ="resetkey";
      const userData ={ 
                  id :userDetails.id,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          }
      //console.log(userData)
      dispatches(saveAction(userData,path,navigate));
  }

  useEffect(()=>{
    const fetchApikeyData = async (userID) => {
      try 
      {
         const response = await fetchItemsApiKey(userID);
         setDataApikey(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

       fetchApikeyData(userID)
    },[])
    
    //console.log(dataApikey);
    
    // const fetchApikeyData =()=>{
    //     axios.get(`${baseUrl}resetkey/${userID}`).then(response => 
    //       {
    //          console.log(response.data.data[0].apikey);
    //          setDataApikey(response.data.data[0].apikey);
    //           }) .catch((error) => {
    //           if (error.response) {
    //               console.log(error.response);
    //               console.log("server responded");
    //           } else if (error.request) {
    //               console.log("network error");
    //           } else {
    //               console.log(error);
    //           }
    //           });
    //   }
  

  return (
    <Fragment>     
      <div className="container-fluid">
        <div className="element-area">
          <div className="demo-view">
            <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
              <Row>
                <Col xl={8}>
                <form onSubmit={handleSubmit}>
                  <Tab.Container defaultActiveKey="Preview">
                    <Card name="basic-dropdown" className="dz-card">
                      <Card.Header className="flex-wrap border-0">
                        <div>
                          <Card.Title>Api Key</Card.Title>
                          <Card.Text className="m-0 subtitle">
                           This is a secret key for api. It can be reset button below.
                          </Card.Text>
                        </div>
                        <Nav as="ul" className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
                          <Nav.Item as="li" className="nav-item" role="presentation">
                            <Nav.Link as="button"  type="button" eventKey="Preview">Hide</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="nav-item" >
                            <Nav.Link as="button"  type="button" eventKey="Code">Review</Nav.Link>
                          </Nav.Item>
                        </Nav>  
                      </Card.Header>

                      <Tab.Content>
                        <Tab.Pane eventKey="Preview">
                          <Card.Body>
                            <div className="basic-dropdown">

                               <div className="card-footer">
                                    <button className="btn btn-primary">Reset APIKey</button>
                                </div>
                                
                            </div>
                          </Card.Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Code">
                        <div className="card-body pt-0 p-0 code-area">
                                <pre className="mb-0">
                                    <code className="language-html">
                                        <Highlight>
                                          {/* {dataApikey &&
                                            <input type="text" className="form-control" value={dataApikey} placeholder="" readOnly />
                                          }  */}
                                         {apikey &&
                                           
                                           <input type="text" className="form-control" value={apikey} placeholder="" readOnly />
                                         } 
                                                {/* <label>{userDetails.apikey}</label> */}
                                        </Highlight>
                                </code></pre>
                            </div>
                        </Tab.Pane>
                      </Tab.Content>  
                    </Card>
                  </Tab.Container>  
                </form>
                </Col>
              </Row>
              
            </div>
          </div>
         
        </div>
      </div>

    </Fragment>
  );
};

export default Index;
