import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import { useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import TranscriptHeader from "../../../../../src/images/templates/header_transcript.jpg";
import TranscriptFooter from "../../../../../src/images/templates/footer_treanscript.jpg";
import { 
  Page, Text, View, Document, StyleSheet   
 } from '@react-pdf/renderer';
 import { PDFViewer } from '@react-pdf/renderer';
 import axios from 'axios'
const Index = () => 
{
  const pdfRef=useRef();
  const downloadPDF = () =>{
    const input = pdfRef.current;
    html2canvas(input).then((canvas)=>{
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px','a5',true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth/imgWidth, pdfHeight/imgHeight);
      const imgX =(pdfWidth - imgWidth * ratio) / 2;
      const imgY=30;
      pdf.addImage(imgData, 'PNG', imgX, imgY,imgWidth* ratio, imgHeight* ratio);
      pdf.save();
    });
  }
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userID = userDetails.userID;
  const matricno =  sessionStorage.getItem('matricno');
  const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [dataResult, setDataResult]=useState([]);
  const [datasemesterResult, setDataSemesterResult]=useState([]);
  useEffect(()=>
  {
    loadResult();
    loadSemesterResults();
  },[matricno])


  function loadResult(){
    const  userData = { matricno: matricno, userID:userDetails.userID, createdBy:userDetails.email }
    axios.post(`${baseUrl}generatetranscript/`,userData,{headers}).then(response => 
    {              
        if(response.data.statuscode=== 200)
        {
          setDataResult(response.data.data);
        }
        }) .catch((error) => {
        if (error.response) {
           
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });

  }
  
  function loadSemesterResults()
  {
    const  userData = { matricno: matricno, userID:userDetails.userID, createdBy:userDetails.email }
    axios.post(`${baseUrl}getsemesteresult/`,userData,{headers}).then(response => 
      {              
          if(response.data.statuscode=== 200)
          {
            setDataSemesterResult(response.data.data);
          }
          }) .catch((error) => {
          if (error.response) {
             
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
  



 // Group dataResult by level
 const groupedData = dataResult.reduce((result, item) => {
  const { level, semester } = item;
  if (!result[level]) {
    result[level] = {};
  }
  if (!result[level][semester]) {
    result[level][semester] = [];
  }
  result[level][semester].push(item);
  return result;
  //const totalPoints = items.reduce((sum, item) => sum + item.point, 0);
}, {});


const calculateTotalCourseUnits = (items) => {
  const uniqueCourseCodesSet = new Set();
  
  // Iterate through the items array
  return items.reduce((sum, item) => {
    // Check if the coursecode is already processed
    if (!uniqueCourseCodesSet.has(item.coursecode)) {
      // Add the coursecode to the set (Set will automatically keep it unique)
      uniqueCourseCodesSet.add(item.coursecode);
      
      // Add the courseunit to the sum
      return sum + item.courseunit;
    }

    // If the coursecode is already processed, return the current sum
    return sum;
  }, 0);
};

// Function to calculate the sum of points
const calculateTotalPoints = (items) => {
  const uniqueCourseCodesSet = new Set();
  
  // Iterate through the items array
  return items.reduce((sum, item) => {
    // Check if the coursecode is already processed
    if (!uniqueCourseCodesSet.has(item.coursecode)) {
      // Add the coursecode to the set (Set will automatically keep it unique)
      uniqueCourseCodesSet.add(item.coursecode);
      
      // Add the point to the sum
      return sum + item.point;
    }

    // If the coursecode is already processed, return the current sum
    return sum;
  }, 0);
}; 

const getSemesterGPA = (level, semester, session) => {
  const filteredGPA = datasemesterResult.filter(result => 
    result.level === level && result.semester === semester && result.session === session
  );

  // Log the filtered array
  console.log('Filtered GPA:', filteredGPA);

  // You can further process or display the filtered array as needed

  return filteredGPA;
};
//console.log(groupedData);

  return (
    <Fragment>
      {/* <PageTitle activeMenu="Bootstrap" motherMenu="Table" /> */}
      <div className="container-fluid" ref={pdfRef}>
     
        <Row>
          
           <img src={TranscriptHeader} alt="" /> 
          <Col lg={12}>
            <Card>
             
              <Card.Body>
                {Object.entries(groupedData).map(([level, semester]) => (
                  <div key={level}>
                  {Object.entries(semester).map(([semesters, items]) => {
                      
                    const uniqueCourseCodes = new Set();
                    const semesterGPAData = getSemesterGPA(items);
                    return (
                      <div key={semesters}>
                      
                        <Card.Header>
                             <Card.Title>{semesters} Semester {items[0].session} Session {level} level</Card.Title>
                         </Card.Header>
                        <Table responsive className="header-border">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Course Title</th>
                              <th>Course unit</th>
                              <th>Score</th>
                              <th>Point</th>
                              <th>Grade</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => {
                              if (!uniqueCourseCodes.has(item.coursecode)) {
                                uniqueCourseCodes.add(item.coursecode);
                                return (
                                  <tr key={index}>
                                    <td>{item.coursecode}</td>
                                    <td>{item.coursetitle}</td>
                                    <td>{item.courseunit}</td>
                                    <td>{item.score}</td>
                                    <td>{item.point}</td>
                                    <td>{item.grade}</td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </Table>

                        <div className="cumulative-info">
                          <label style={{ color: 'red' }}>
                            TNU : {calculateTotalCourseUnits(items)} &nbsp;&nbsp;
                            TCP : {calculateTotalPoints(items)} &nbsp;&nbsp;
                            GPA : {getSemesterGPA(level,items[0].semesterid,items[0].sessionid )}&nbsp;&nbsp;
                            CGPA : {items[0].cgpa}&nbsp;&nbsp;

                           </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                ))}
                
            </Card.Body>
            
            </Card>
          </Col>
        
        
        </Row>
        {/* <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Second Semester 2002/2003 Session</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive className="header-border ">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Course Title</th>
                      <th>Unit</th>
                      <th>Score</th>
                      <th>Grade</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>COM123</td>
                      <td>Computer Packages</td>
                      <td>2</td>               
                      <td>72</td>
                      <td>AB</td>
                    </tr>
                    <tr>
                      <td>GNS102</td>
                      <td>Communication In English 1</td>
                      <td>2</td>               
                      <td>66</td>
                      <td>B</td>
                    </tr>
                    <tr>
                      <td>MTN112</td>
                      <td>Trigonometry & Analytical Geometry</td>
                      <td>2</td>               
                      <td>88</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>STA122</td>
                      <td>Statistical Theory</td>
                      <td>2</td>               
                      <td>78</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>STA121</td>
                      <td>Descriptive Statistics II</td>
                      <td>3</td>               
                      <td>62</td>
                      <td>BC</td>
                    </tr>
                    <tr>
                      <td>STA112</td>
                      <td>Descriptive Statistics 1</td>
                      <td>2</td>               
                      <td>62</td>
                      <td>BC</td>
                    </tr>
                    <tr>
                      <td>STA123</td>
                      <td>Demography</td>
                      <td>2</td>               
                      <td>73</td>
                      <td>AB</td>
                    </tr>
                  </tbody>
                </Table>
                <label style={{ color:'red' }}>SEM.CREDITS ATTEMPTED: 13; TCP EARNED: 21.30; CUM TNU:42.0, CUM TCP:93.50, CUM CREDITS PASSED 28.0; GPA 3.50; CGPA: 3.34</label>

              </Card.Body>
            </Card>
          </Col>
        
        
        </Row>

        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>First Semester 2003/2004 Session</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive className="header-border ">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Course Title</th>
                      <th>Unit</th>
                      <th>Score</th>
                      <th>Grade</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>COM111</td>
                      <td>Introduction to Computer</td>
                      <td>2</td>               
                      <td>55</td>
                      <td>C</td>
                    </tr>
                    <tr>
                      <td>GNS101</td>
                      <td>Use of English 1</td>
                      <td>2</td>               
                      <td>62</td>
                      <td>BC</td>
                    </tr>
                    <tr>
                      <td>GNS127</td>
                      <td>Citizenship Education 1</td>
                      <td>2</td>               
                      <td>68</td>
                      <td>B</td>
                    </tr>
                    <tr>
                      <td>MTH111</td>
                      <td>Logic, Linear Algebra & Sets</td>
                      <td>2</td>               
                      <td>75</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>STA111</td>
                      <td>Introduction to Statistics</td>
                      <td>2</td>               
                      <td>68</td>
                      <td>B</td>
                    </tr>
                    <tr>
                      <td>STA112</td>
                      <td>Descriptive Statistics 1</td>
                      <td>2</td>               
                      <td>73</td>
                      <td>AB</td>
                    </tr>
                    <tr>
                      <td>STA113</td>
                      <td>Elementary Probability Theory</td>
                      <td>2</td>               
                      <td>50</td>
                      <td>CD</td>
                    </tr>
                  </tbody>
                </Table>
                <label style={{ color:'red' }}>SEM.CREDITS ATTEMPTED: 15; TCP EARNED: 22.40; CUM TNU:15.0, CUM TCP:48.00, CUM CREDITS PASSED 15.0; GPA 3.20; CGPA: 3.20</label>
              </Card.Body>
            
            </Card>
          </Col>
        
        
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Second Semester 2003/2004 Session</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive className="header-border ">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Course Title</th>
                      <th>Unit</th>
                      <th>Score</th>
                      <th>Grade</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>COM123</td>
                      <td>Computer Packages</td>
                      <td>2</td>               
                      <td>72</td>
                      <td>AB</td>
                    </tr>
                    <tr>
                      <td>GNS102</td>
                      <td>Communication In English 1</td>
                      <td>2</td>               
                      <td>66</td>
                      <td>B</td>
                    </tr>
                    <tr>
                      <td>MTN112</td>
                      <td>Trigonometry & Analytical Geometry</td>
                      <td>2</td>               
                      <td>88</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>STA122</td>
                      <td>Statistical Theory</td>
                      <td>2</td>               
                      <td>78</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>STA121</td>
                      <td>Descriptive Statistics II</td>
                      <td>3</td>               
                      <td>62</td>
                      <td>BC</td>
                    </tr>
                    <tr>
                      <td>STA112</td>
                      <td>Descriptive Statistics 1</td>
                      <td>2</td>               
                      <td>62</td>
                      <td>BC</td>
                    </tr>
                    <tr>
                      <td>STA123</td>
                      <td>Demography</td>
                      <td>2</td>               
                      <td>73</td>
                      <td>AB</td>
                    </tr>
                  </tbody>
                </Table>
                <label style={{ color:'red' }}>SEM.CREDITS ATTEMPTED: 13; TCP EARNED: 21.30; CUM TNU:42.0, CUM TCP:93.50, CUM CREDITS PASSED 28.0; GPA 3.50; CGPA: 3.34</label>

              </Card.Body>
            </Card>
          </Col>
        
          <img src={TranscriptFooter} alt="" /> 
        </Row>
        */}
      </div>
       <div className="container">
            <button className="btn btn-primary" onClick={downloadPDF}>Download Now</button>
        </div>
    </Fragment>
  );

}

export default Index;
