
import { ColumnFilter } from '../ColumnFilter';


export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
	},
	{
		Header : 'School Name',
		Footer : 'School Name',
		accessor: 'shortname',
		Filter: ColumnFilter,
	},
	{
		Header : 'Title',
		Footer : 'Title',
		accessor: 'title',
		Filter: ColumnFilter,
	},
    {
		Header : 'Code',
		Footer : 'Code',
		accessor: 'code',
		Filter: ColumnFilter,
	},
	
    {
		Header : 'Unit',
		Footer : 'Unit',
		accessor: 'unit',
		Filter: ColumnFilter,
	},
	{
		Header : 'Action',
		Footer : 'Action',
		accessor: 'button',
		Filter: ColumnFilter,
	},
	

	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Title',
		Footer : 'Title',
		columns: [
			{
				Header : 'Code',
				Footer : 'Code',
				accessor: 'code',
			}
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Title',
				Footer : 'Title',
				accessor: 'title',
			},
			{
				Header : 'Title',
				Footer : 'Title',
				accessor: 'title',
			},
		]
	},
]