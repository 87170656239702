import React,{useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import { IMAGES } from '../../../../constant/theme';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import { saveAction, getServiceAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItems} from '../../Lists/Country';
import  { fetchItemState} from '../../Lists/State';
//import { fetchItems } from './api';
const inputName = [
    { label:'School Name', value:'Federal University Of Technology Akure' },
 
];

const Index = () => {
    const [itemsCountry, setItemsCountry] = useState([]);
    const [error, setError] = useState(null);
    const [dataCountry, setDataCountry] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [userinfo, setUserInfo] =useState([]);
    const [logopath, setFilelogopath] = useState();
    const [selectCountryValue, setselectCountryValue] = useState();
    const [selectStateValue, setselectStateValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [dataState, setDataState] = useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//    function handleChange(e) {
      
//     setFilelogopath(URL.createObjectURL(e.target.files[0]));
//    }

   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
        setFilelogopath(e.target.files[0]);
        console.log(logopath);
    }
  };
 function handleSubmit(e) 
 {
    e.preventDefault();
    //console.log(baseUrl);
    if (!logopath) {
        return;
      }
      var formData = new FormData();
      formData.append("fileImage",logopath); 
   

  const path ="schoolsetup";
  const userData ={
               address:e.target.address.value,
               phone:e.target.phone.value,
               slogan:e.target.slogan.value,
               city:e.target.city.value,
               shortname:e.target.shortname.value,
               state:selectStateValue.value,
               school:e.target.school.value,
               country:selectCountryValue.value,
               logopath:logopath,
               userID:userDetails.userID,
               createdBy:userDetails.email,
     }
    dispatches(saveAction(userData,path,navigate));
 }

 useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItems();
        const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
        setItemsCountry(customHeadings);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []); 

    const fetchStateData = async (countryID) => {
      try {
        const response = await fetchItemState(countryID);
        const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
         setDataState(customHeadings);
      } catch (error) {
        setError(error.message);
      }
    };



const selectCountryOnChange = (selectedOption) => 
{
  setFieldValue('country', selectedOption.value);
  setselectCountryValue(selectedOption)
  const countryID = selectedOption.value;
  fetchStateData(countryID)
}

const selectStateOnChange = (selectedOption) => 
{
  setFieldValue('country', selectedOption.value);
  setselectStateValue(selectedOption);
}
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="clearfix">
                            <div className="card card-bx profile-card author-profile m-b30">
                                <div className="card-body">
                                    <div className="p-5">
                                        <div className="author-profile">
                                            <div className="author-media">
                                                 {logopath &&  <img src={logopath} alt="" /> }  {!logopath && <img src={IMAGES.User} alt="" /> }
                                              
                                                <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                    <input type="file" onChange={handleFileChange} id='logopath' name="logopath"  className="update-flie" required />
                                                    <i className="fa fa-camera"></i>
                                                </div>
                                                
                                              
                                            </div>
                                            <div className="author-info">
                                                <h6 className="title">Upload School Logo</h6>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="info-list">
                                        <ul>
                                            <li><Link to={"/app-profile"}>Models</Link><span>36</span></li>
                                            <li><Link to={"/uc-lightgallery"}>Gallery</Link><span>3</span></li>
                                            <li><Link to={"/app-profile"}>Lessons</Link><span>1</span></li>
                                        </ul>
                                    </div> */}
                                </div>
                                {/* <div className="card-footer">
                                    <div className="input-group mb-3">
                                        <div className="form-control rounded text-center bg-white">Portfolio</div>
                                    </div>
                                    <div className="input-group">
                                        <a href="https://www.dexignzone.com/" target="blank" className="form-control text-primary rounded text-start bg-white">https://www.dexignzone.com/</a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Account </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        { inputName.map((item, ind)=>(
                                            <div className="col-sm-12 m-b30">
                                                <label className="form-label">{item.label}</label>
                                                <input type="text" className="form-control" name="school" id="school" placeholder='Enter School Name' required  />
                                            </div>
                                        ))}
                                         <div className="col-sm-12 m-b30">
                                            <label className="form-label">Slogan</label> 
                                            <input type="text" className="form-control" name="slogan" id="slogan" placeholder="Transcript Application Made Simple" required />
                                        </div>
                                       <div className="col-sm-12 m-b30">
                                            <label className="form-label">Address</label> 
                                            <input type="text" className="form-control" name="address" id="address" placeholder="Akure" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">School ShortName</label>
                                            <input type="text" className="form-control" name="shortname" id="shortname" placeholder="FUTA" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" name="phone" id="phone" placeholder="+234 805 6789 9000" required />
                                        </div>
                                       
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={itemsCountry} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryOnChange} 
                                                placeholder="Country"
                                                isSearchable={true} required
                                            />
                                              {/* <Select placeholder='Country ' name="searchValueCountry"  isSearchable={true} value={selectCountryValue} onChange={selectCountryOnChange} options={dataCountry} required  /> */}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">State</label>
                                            <Select options={dataState}  className="custom-react-select"  onChange={selectStateOnChange} value={selectStateValue}
                                                placeholder="States"
                                                isSearchable={true} required
                                            />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">City</label> 
                                            <input type="text" className="form-control" name="city" placeholder="Akure" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Account</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;